<router-outlet></router-outlet>
<ModalComponent 
    [icon]="modal.iconUrl"
    [displayModal]="showModal" 
    [title]="modal.title"
    [buttons]="modal.textButtons"
    (displayModalEvent)="displayBaseModal($event)" 
    (buttonSelectedEvent)="readButtonSelected($event)">
    <p>
        {{ modal.message }}
    </p>
</ModalComponent>