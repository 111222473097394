import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Settings } from 'src/app/interfaces/globals';
import { I_Result } from 'src/app/interfaces/http-response.interface';
import { I_Tabs } from 'src/app/interfaces/legals.interface';
import { ModalStatus } from 'src/app/interfaces/modal-status.interface';
import { ModalStatusService } from 'src/app/services/modal-status.service';
import { RequestsBaseService } from 'src/app/services/requests/request-base/requests-base.service';
import { Navigation } from 'src/app/utils/navigation';
import { RegularExpressions } from 'src/app/utils/regular-expressions';

declare var SETTINGS : Settings;

interface I_Platform {
	id : number,
	name : string
}

interface I_Legals_Response {
	title : string, 
	text : string
}

@Component({
	selector: 'RegisterFormComponent',
	templateUrl: './register-form.component.html',
	styleUrls: ['./register-form.component.css']
})
export class RegisterFormComponent {

	constructor(private _router : Router, private _regEx : RegularExpressions, private _request : RequestsBaseService, private _activatedRoute : ActivatedRoute, private _notificationService: ModalStatusService) { }

	protected _navigation = new Navigation(this._router, this._activatedRoute);

    frmElement : FormGroup = new FormGroup({
        name            : new FormControl<string>('', [Validators.required, Validators.pattern(this._regEx.getRegEx("ONLY_TEXT"))]),
        lastName        : new FormControl<string>('', [Validators.required, Validators.pattern(this._regEx.getRegEx("ONLY_TEXT"))]),
        mothersLastName : new FormControl<string>('', [Validators.required, Validators.pattern(this._regEx.getRegEx("ONLY_TEXT"))]),
        email           : new FormControl<string>('', [Validators.required, Validators.pattern(this._regEx.getRegEx("EMAIL_FORMAT"))]),
        phone           : new FormControl<string>('', [Validators.required, Validators.pattern(this._regEx.getRegEx("ONLY_NUMBERS")), Validators.minLength(10)]),
        zipCode         : new FormControl<string>('', [Validators.required, Validators.pattern(this._regEx.getRegEx("ONLY_NUMBERS")), Validators.minLength(5)]),
        platformId      : new FormControl<number>(0, Validators.pattern(this._regEx.getRegEx("NO_ZERO_OR_LESS"))),
        comment         : new FormControl<string>(''),
		termsAccepted 	: new FormControl<boolean>(false,  Validators.pattern(this._regEx.getRegEx("ONLY_TRUE"))),
    });

	legalsData : I_Tabs[] = [];
	displayModalInvalidForm : boolean = false;
	displayModalTermsNotAccepted : boolean = false;
	displayModalLegals : boolean = false;
	legalModalButtons : string[] = ["Cerrar"];
	formModalButtons : string[] = ["Aceptar"];
	termsNotAcceptedButtons : string[] = ["Ver aviso"];
	invalidFields : string[] = [];
	platformList : I_Platform[] = [];
	
	/**
	 * Load initial methods.
	 *
	 * @memberof RegisterFormComponent
	 */
	ngOnInit() : void {

		this.getPlatformList();

		this.setPlatform();

		this.getLegalsData();
		
	}

	/**
	 * Get the list of platform.
	 *
	 * @memberof RegisterFormComponent
	 */
	getPlatformList() : void {

		this.platformList.push
		(
			{
				id : 0,
				name : "-- Seleccionar --"
			},
			{
				id : SETTINGS.BRIO_APP_YOMP_MOBILE.APP_ID,
				name : SETTINGS.BRIO_APP_YOMP_MOBILE.NAME
			},
			{
				id : SETTINGS.BRIO_APP_YOMP_PREMIUM.APP_ID,
				name : SETTINGS.BRIO_APP_YOMP_PREMIUM.NAME
			},
			{
				id : SETTINGS.BRIO_APP_YOMP_PLUS.APP_ID,
				name : SETTINGS.BRIO_APP_YOMP_PLUS.NAME
			}
		)

	}

	/**
	 * Find platform in query param.
	 *
	 * @memberof RegisterFormComponent
	 */
	setPlatform() : void {

		let platform : string = this._navigation.getQueryParamValue("platform");

		let platformFound = this.platformList.find(item => item.name.toLocaleLowerCase().includes(platform));

		this.frmElement.controls['platformId'].setValue(platformFound?.id);

	}

	/**
	 * Get the legals data.
	 *
	 * @memberof RegisterFormComponent
	 */
	getLegalsData() : void {
		
		let url : string = this._request.buildURL(
			"CATALOGS", 
			SETTINGS.API_SETTINGS.CATALOGS_API.V2_LEGAL_DOCUMENT_LIST_ENDPOINT.URL
			);

		this._request.sendRequest("GET", url, { "CountryId" : 1, "AppId" : SETTINGS.API_SETTINGS.CATALOGS_API.LANDING_PAGE_APP_ID }, true).subscribe((response : I_Legals_Response[]) => {
			if(response.length > 0) {
				this.setClasesAndLegalData(response);
			}
		});

		
	}

	/**
	 * Set legal data.
	 *
	 * @param {any[]} response
	 * @memberof RegisterFormComponent
	 */
	setClasesAndLegalData(response : I_Legals_Response[]) : void {

		for (let i = 0; i < response.length; i++) {
			this.legalsData.push(
				{
					title : response[i].title,
					content : response[i].text,
					cssClassTab : "nav-link",
					idTab : "id-" + (i + 1),
					dataBsTarget : "#target-" + (i + 1) + "-pane",
					ariaControls : "target-" + (i + 1) + "-pane",
					ariaSelected : true,
					cssClassContent : "tab-pane fade show active",
					idContent : "target-" + (i + 1) + "-pane",
					ariaLabelledby : "id-" + (i + 1)
				}
			);

			if(i == 0) {
				this.legalsData[i].ariaSelected = true;
				this.legalsData[i].cssClassTab = "nav-link active";
				this.legalsData[i].cssClassContent = "tab-pane fade show active";
			}
		}

		this.legalsData[0].cssClassTab = 
			this.legalsData[0].cssClassTab.concat(" isFirstTab");

		this.legalsData[this.legalsData.length - 1].cssClassTab = 
			this.legalsData[this.legalsData.length - 1].cssClassTab.concat(" isLastTab");

	}

	/**
	 * Validate is data can submit.
	 *
	 * @return {*}  {boolean}
	 * @memberof RegisterFormComponent
	 */
	validataSubmitData() : boolean {
		let canSubmit : boolean = true;

		let invalidFields : string[] = this._regEx.getInvalidControls(this.frmElement);

		if(invalidFields.length == 1 && invalidFields[0] == "termsAccepted") {
			this.displayModalTermsNotAccepted = true;
		}
		else if(invalidFields.length > 0) {
			this.traslateFields(invalidFields);
			this.displayModalInvalidForm = true;
		}
		else {
			this.displayModalTermsNotAccepted = false;
			this.displayModalInvalidForm = false;
		}
		
		canSubmit = !this.displayModalTermsNotAccepted && !this.displayModalInvalidForm;

		return canSubmit;

	}

	/**
	 * Detect change from child component for display the modal.
	 *
	 * @param {*} event
	 * @memberof RegisterFormComponent
	 */
	displayModayInvalidFormUpdatedForChild(event : boolean) : void {
		this.displayModalInvalidForm = event;
	}

	/**
	 * Detect change from child component for display the modal.
	 *
	 * @param {boolean} event
	 * @memberof RegisterFormComponent
	 */
	displayModayLegalsUpdatedForChild(event : boolean) : void {
		this.displayModalLegals = event;
	}

	/**
	 * Display modal legals.
	 *
	 * @memberof RegisterFormComponent
	 */
	acceptLegals() : void {

		if(!this.displayModalInvalidForm) {
			this.displayModalLegals = true;
		}

	}

	/**
	 * Get modal button selected.
	 *
	 * @param {string} button
	 * @memberof RegisterFormComponent
	 */
	readButtonSelected(button : string) :  void {
		button = button.toLocaleLowerCase();

		if(button == "ver aviso") {
			this.displayModalTermsNotAccepted = false;
			this.displayModalLegals = true;
		}
	}

	/**
	 * Get invalid fields translated.
	 *
	 * @memberof RegisterFormComponent
	 */
	traslateFields(invalidFields : string[]) : void {

		this.invalidFields = [];
		
		invalidFields.forEach(field => {

			let fieldName : string = "";

			switch (field) {
				case "name":
					fieldName = "Nombre(s)";	
				break;
				case "lastName":
					fieldName = "Apellido Paterno";
				break;
				case "mothersLastName":
					fieldName = "Apellido Materno";
				break;
				case "email":
					fieldName = "Correo electrónico";
				break;
				case "phone":
					fieldName = "Celular";
				break;
				case "zipCode":
					fieldName = "Código postal";
				break;
				case "platformId":
					fieldName = "Plataforma";
				break;
				case "termsAccepted":
					fieldName = "Aviso de privacidad";
				break;
				default:
				break;
			}

			this.invalidFields.push(fieldName);

		});

	}

	/**
	 * Send data to API.
	 *
	 * @memberof RegisterFormComponent
	 */
	submitData() : void {
		if(this.validataSubmitData() && !this._request.sending) {
			let url : string = this._request.buildURL
			(
				"PARTNERS", 
				SETTINGS.API_SETTINGS.PARTNERS_API.PROSPECT_CLIENT_INFORMATION_CONTROLLER.URL
			)
			this._request.sending = true;
			
			this._request.sendRequest("POST", url, this.frmElement.value, true).subscribe((response : boolean | I_Result) => {
				this._request.sending = false;
				let modalData : ModalStatus = {
					iconUrl : "",
					title : "",
					message : "",
					allowDismiss : false,
					textButtons : ["Aceptar"]
				}
				if(response == true) {
					modalData.iconUrl = "../../../assets/icons/png/ic_success.png"
					modalData.title = "Información enviada";
					modalData.message = "Su información ha sido enviada \n con éxito."
				}
				else if(typeof(response) == "boolean") {
					modalData.iconUrl = "../../../assets/icons/png/ic_error.png"
					modalData.title = "Error";
					modalData.message = "Ha ocurrido un error, por favor \n intentarlo más tarde. \n \n Si el error persiste comunicarse al \n XXXXXXXXXX";
				}
				this._notificationService.show(modalData);
				this._notificationService.subscription$ = this._notificationService.confirmationResponse$.subscribe(response => {
					if(response == "aceptar") {
						this._router.navigate(["list-view"]);
					}
				});
			});
		}
	}

	/**
	 * Return title of legal modal.
	 *
	 * @return {*}  {string}
	 * @memberof RegisterFormComponent
	 */
	legalDataTitle() : string {

		let title : string = "";

		if(this.legalsData.length > 1) {

			title = "Avisos legales";

		}
		else if (this.legalsData.length > 0) {

			title = this.legalsData[0].title;			

		}

		return title;

	}
	
	/**
	 * Clear values.
	 *
	 * @memberof RegisterFormComponent
	 */
	ngOnDestroy() : void {
		this._notificationService.clearModal();
	}  

}