import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { ListViewsComponent } from "./components/list-views/list-views.component";
import { CommissionsSimulatorComponent } from "./components/navigation/commissions-simulator/commissions-simulator.component";
import { RegisterFormComponent } from "./components/navigation/register-form/register-form.component";
import { YompMobileStepsComponent } from "./components/navigation/yomp-mobile-steps/yomp-mobile-steps.component";
import { UsComponent } from "./components/transicion-views/us/us.component";

const links : Routes = [
    { path : '',                        component : HomeComponent },
    { path: 'register-form',            component : RegisterFormComponent },
    { path: 'commissions-simulator',    component : CommissionsSimulatorComponent },
    { path: 'yomp-mobile-steps',        component : YompMobileStepsComponent },
    { path: 'list-view',                component : ListViewsComponent },
    { path: 'us',                       component : UsComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' }
];

export const LINKS = RouterModule.forRoot(links, { useHash : true });