import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

@Injectable()
export class ScrollService {

    private componentSubject = new BehaviorSubject<string>("");

    public component$ = this.componentSubject.asObservable();

    public subscription$ = new Subscription();
    
    /**
     * Scroll by component name.
     *
     * @param {string} component
     * @memberof ScrollService
     */
    scrollTo(component : string) : void {

        if(component != "" && component != undefined)
            this.componentSubject.next(component);
        
    }

    /**
     * Reset default values of service.
     *
     * @memberof ScrollService
     */
    clearScroll() : void {
        this.componentSubject.next("");
        this.subscription$.unsubscribe();
    }

}