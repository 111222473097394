<div class="container-title">
    <p>Preguntas <br> frecuentes</p>
</div>
<div class="container-detail">
    <div *ngFor="let question of frequentQuestions; let i = index" [class]="question.cssClass">
        <p>
            <button class="button-white-transparent button-question" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="setToggleSetting(true, i)" aria-expanded="false" [attr.aria-controls]="setToggleSetting(false, i)" (mouseenter)="setAnswerPosition(i)" (click)="questionSelected(i)" [disabled]="question.dishabled">
                {{ question.question }}
                <img src="../../../../assets/icons/png/ir.png">
            </button>
        </p>
    </div>
</div>
<div [id]="collapseAnswerPosition" [class]="collapseSettings">
    <div class="card">
        <div class="card-header">
            <p class="card-text">{{ frequentQuestion.question }}</p>
        </div>
        <div class="card-body">
            <div class="container-scroll-answers">
                <p [id]="frequentQuestion.idAnswer" class="card-text" [innerHTML]="navigationTo(frequentQuestion.idAnswer, frequentQuestion.answers) | HtmlSanitizer"></p>
            </div>
        </div>
        <div class="card-footer">
            <button *ngIf="frequentQuestion.question != ''" class="button-white-transparent btn-cancel" (click)="closeAnswer()">Cerrar</button>
        </div>
    </div>
</div>