import { Component, HostBinding } from '@angular/core';
import { slide } from 'src/app/animations/transicion';

@Component({
	selector: 'UsComponent',
	templateUrl: './us.component.html',
	styleUrls: ['./us.component.css'],
  animations: [slide]
})
export class UsComponent {

	@HostBinding('@routeAnimation') routeAnimation = true;

}