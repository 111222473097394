import { Component, HostBinding } from '@angular/core';
import { I_Icon_Title } from 'src/app/interfaces/icon-title.interface';
import { slide } from 'src/app/animations/transicion';
import { ActivatedRoute, Router } from '@angular/router';
import { Navigation } from 'src/app/utils/navigation';

@Component({
  selector: 'HomeComponent',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [slide]
})
export class HomeComponent {

  @HostBinding('@routeAnimation') routeAnimation = true;
  services : I_Icon_Title[] = [];
  navigateToNextNiew : string = 'list-view';
  autoNavigationTimer : NodeJS.Timeout | undefined;
  protected _navigation = new Navigation(this._router, this._activatedRoute);

  constructor(private _router : Router, private _activatedRoute : ActivatedRoute) { }

  /**
   * Load initial methods.
   *
   * @memberof HomeComponent
   */
  ngOnInit() : void {
    this.setServices();
    this.autoNavigation();
  }

  /**
   * Set the services.
   *
   * @memberof HomeComponent
   */
  setServices() : void {

    this.services.push(
      {
        icon: "../../../../assets/icons/png/ic_abarrote_express.png",
        title: "Abarrote Express"
      },
      {
        icon : "../../../../assets/icons/png/ic_pago_con_tarjeta.png",
        title: "Pago con tarjeta"
      },
      {
        icon: "../../../../assets/icons/png/ic_pago_servicios.png",
        title: "Pago de servicios"
      },
      {
        icon : "../../../../assets/icons/png/ic_tiempo_aire.png",
        title: "Tiempo aire"
      },
      {
        icon: "../../../../assets/icons/png/ic_reportes.png",
        title: "Reportes"
      }
    )
  }

  /**
   * Navigation after delay.
   *
   * @memberof HomeComponent
   */
  autoNavigation() : void {
    this.autoNavigationTimer = setTimeout(() => {
      this._router.navigate([this.navigateToNextNiew]);
    }, 7000);
  }

  /**
   * Navigation to register form.
   *
   * @memberof HomeComponent
   */
  registerFormNavigation() : void {
    clearTimeout(this.autoNavigationTimer);
    this._navigation.goNavigation('register-form');
  }
}