<div class="container-title">
    <p>Servicios yomp!</p>
</div>
<div class="container-subtitle">
    <p>¿Qué plataforma se adecúa mejor a mi negocio?</p>
</div>
<div class="container-table">
  <div class="container-table-scroll">
    <table class="table">
      <thead>
        <tr>
          <th class="margin-table"></th>
          <th class="service-name">Servicio</th>
          <th class="service">Premium</th>
          <th class="service">Mobile</th>
          <th class="margin-table"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="margin-table"></td>
          <th>Manejo de inventario</th>
          <td>
              <div class="circle"></div>
          </td>
          <td>
              <div class="circle"></div>
          </td>
          <td class="margin-table"></td>
        </tr>
        <tr>
          <td class="margin-table"></td>
          <th>Reportes de venta</th>
          <td>
              <div class="circle"></div>
          </td>
          <td></td>
          <td class="margin-table"></td>
        </tr>
        <tr>
          <td class="margin-table"></td>
          <th>Pago de servicios</th>
          <td>
              <div class="circle"></div>
          </td>
          <td>
              <div class="circle"></div>
          </td>
          <td class="margin-table"></td>
        </tr>
        <tr>
          <td class="margin-table"></td>
          <th>Recargas y venta tiempo aire</th>
          <td>
              <div class="circle"></div>
          </td>
          <td>
              <div class="circle"></div>
          </td>
          <td class="margin-table"></td>
        </tr>
        <tr>
          <td class="margin-table"></td>
          <th>Pagos con tarjeta</th>
          <td>
              <div class="circle"></div>
          </td>
          <td>
              <div class="circle"></div>
          </td>
          <td class="margin-table"></td>
        </tr>
        <tr>
          <td class="margin-table"></td>
          <th>Surte tu negocio con abarrote Express*</th>
          <td>
              <div class="circle"></div>
          </td>
          <td>
              <div class="circle"></div>
          </td>
          <td class="margin-table"></td>
        </tr>
        <tr>
          <td class="margin-table"></td>
          <th>Renta</th>
          <td>
              <div class="circle"></div>
          </td>
          <td></td>
          <td class="margin-table"></td>
        </tr>
        <tr>
          <td class="margin-table"></td>
          <th>Tableta</th>
          <td>
              <div class="circle"></div>
          </td>
          <td></td>
          <td class="margin-table"></td>
        </tr>
        <tr>
          <td class="margin-table"></td>
          <th>Impresora para tickets*</th>
          <td>
              <div class="circle"></div>
          </td>
          <td></td>
          <td class="margin-table"></td>
        </tr>
        <tr>
          <td class="margin-table"></td>
          <th>Aplicación gratuita</th>
          <td></td>
          <td>
              <div class="circle"></div>
          </td>
          <td class="margin-table"></td>
        </tr>
        <tr>
          <td class="margin-table"></td>
          <th>Dispositivo lector de tarjetas</th>
          <td>
              <div class="circle"></div>
          </td>
          <td></td>
          <td class="margin-table"></td>
        </tr>
        <tr>
          <td class="margin-table"></td>
          <th>Servicio Técnico</th>
          <td>
              <div class="circle"></div>
          </td>
          <td></td>
          <td class="margin-table"></td>
        </tr>
      </tbody>
      <tfoot>
        <tr class=" table-actions">
          <td></td>
          <th class="border-table"></th>
          <td class="border-table">
              <div class="container-commission-button">
                  <button class="button-white-transparent" type="button" (click)="_navigation.goNavigation('register-form', platform)">
                      Contratar
                  </button>
              </div>
          </td>
          <td class="border-table">
              <div class="container-download-now" (click)="getYompMobilePlayStoreUrl()">
                  <img src="../../../../assets/icons/png/ic_google_play.png">
              </div>
          </td>
          <td></td>
        </tr>
      </tfoot>
  </table>
  </div>
</div>