<div class="container-title">
    <p>Haz crecer <br> tu negocio</p>
</div>
<div class="container-detail">
    <div class="items-service">
        <div class="item-service">
            <p> ¡Ofrece a tus clientes más de <br>
                400 servicios!
            </p>
        </div>
    </div>
    <div class="items-service go" (click)="_navigation.goNavigation('register-form')">
        <p class="url">La quiero</p>
        <img src="../../../../assets/icons/png/ir.png">
    </div>
</div>