import { ActivatedRoute, Router } from '@angular/router';

export class Navigation {

    constructor(private _router : Router, private _activatedRoute : ActivatedRoute) { }
	
    /**
	 * Do navigation to components.
	 *
	 * @param {string} url
	 * @param {object} param
	 * @memberof Navigation
	 */
	goNavigation(url : string, param? : object) : void {
		
		if(param == null) {
			this._router.navigate([url]);
		}
		else {
			this._router.navigate([url], { queryParams : param });
		}
	}

	/**
	 * Get the query param value.
	 *
	 * @param {string} name
	 * @return {*}  {*}
	 * @memberof Navigation
	 */
	getQueryParamValue(name : string) : any {

		let value :  any;

		this._activatedRoute.queryParams.subscribe(param => {

			value = param[name];
			
		});

		return value;

	}
}