import { animate, state, style, transition, trigger } from "@angular/animations";

export const slide : any = 
    trigger('routeAnimation', [
        state(
            '*', 
            style({
                opacity: 1,
                transform: 'translateX(0%)'
            })
        ),
        transition(
            ':enter', 
            [
                style({
                    opacity: 0,
                    transform: 'translateX(100%)'
                }),
                animate('1s ease-in')
            ]),
        transition(
            ':leave', 
            [
                animate('1s ease-out', 
                    style({
                        opacity: 0,
                        transform: 'translateX(-100%)'
                    })
                )
            ]
        )
    ]);