import { Component, HostBinding, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { slide } from 'src/app/animations/transicion';
import { I_Frequent_question } from 'src/app/interfaces/frequent-questions.interface';
import { Navigation } from 'src/app/utils/navigation';

@Component({
  selector: 'FrequentQuestionsComponent',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.css'],
  animations: [slide]
})
export class FrequentQuestionsComponent {

  constructor(private _router : Router, private _activatedRoute : ActivatedRoute, private _renderer : Renderer2) { }

  protected _navigation = new Navigation(this._router, this._activatedRoute);

  @HostBinding('@routeAnimation') routeAnimation = true;

  frequentQuestions : I_Frequent_question[] = [
    {
      question: "¿Cómo puedo ser Asociado Yomp?",
      idAnswer: "a-1",
      answers: "Compártenos tus datos y con gusto te contactaremos! <span id='register-form' class='url'>Contacto</span> O comunícate al 81-4160-2222 para obtener más información.",
      cssClass: "",
      dishabled: false
    },
    {
      question: "¿Qué beneficios obtengo al ser Asociado Yomp?",
      idAnswer: "a-2",
      answers: "Convertirse en Asociado Yomp te permitirá ofrecer a tus clientes una amplia gama de servicios que te ayudarán a aumentar tus ganancias y fidelizar a tus clientes. Con nuestra plataforma, podrás mejorar el manejo de tu inventario y obtener reportes detallados de tus ventas diarias. Además, podrás realizar tus pedidos de Abarrotes Express*, lo que te garantiza que siempre tendrás suficiente inventario para satisfacer la demanda de tus clientes. Al unirte a Yomp, tendrás acceso a una amplia variedad de herramientas y recursos que te ayudarán a hacer crecer tu negocio y a aumentar tu lealtad.",
      cssClass: "",
      dishabled: false
    },
    {
      question: "¿Qué servicios puedo ofrecer con Yomp?",
      idAnswer: "a-3",
      answers: "Los principales servicios que le podrás ofrecer a tus clientes son: - Recargas de Tiempo Aire: Telcel, Movistar, AT&T y ¡muchos más!  - Pago de Paquete de Datos Telcel - Pago de servicios: Agua, Luz, Gas, TV de paga, pines electrónicos  - Pedido de Abarrotes Express directo a tu negocio: solo aplica para Monterrey y Guadalajara.",
      cssClass: "",
      dishabled: false
    },
    {
      question: "¿Qué métodos de pago puedo aceptar con Yomp?",
      idAnswer: "a-4",
      answers: "Puedes aceptar pagos con efectivo, tarjeta de debito, crédito, vales de despensa, internacionales y contar con modulo de fiado.",
      cssClass: "",
      dishabled: false
    },
    {
      question: "¿Cuáles son las comisiones por cada servicio?",
      idAnswer: "a-5",
      answers: "Realiza tu simulación de comisiones totalmente gratis! Da clic <span id='commissions-simulator?{platform:premium}' class='url'>aquí</span> para conocer las comisiones de Yomp Premium. Da clic <span id='commissions-simulator?{platform:mobile}' class='url'>aquí</span> para conocer las comisiones de Yomp Mobile.",
      cssClass: "",
      dishabled: false
    },
    {
      question: "¿Cómo puedo utilizar mi dispositivo de cobro con tarjeta?",
      idAnswer: "a-6",
      answers: "Asegúrate de que el dispositivo de cobro con tarjeta cuente con al menos un 20% de batería.  Para vincularlo vía bluetooth a tu tableta, realiza lo siguiente;  1. Validamos que el dispositivo de cobro con tarjeta cuente con batería.  2. En la tableta ingresamos a “Configuración” y damos clic en “Dispositivos conectados”.  3. Damos clic en “Bluetooth” y en Dispositivos vinculados, elegimos el número de serie del dispositivo (este se encuentra en la parte trasera del dongle).  4. Una vez seleccionado, salimos de Configuración e ingresamos a la aplicación Yomp.",
      cssClass: "",
      dishabled: false
    },
    {
      question: "¿Dónde me comunico si requiero hacer una aclaración?",
      idAnswer: "a-7",
      answers: "Para cualquier aclaración puedes comunicarte a nuestro What's App 81-3551-5282 o a nuestra línea de atención al cliente al 81-4160-2222.",
      cssClass: "",
      dishabled: false
    },
    {
      question: "¿Qué beneficios obtengo con Yomp Mobile?",
      idAnswer: "a-8",
      answers: "Con Yomp Mobile, podrás optimizar el manejo de tu inventario, aceptar pagos con tarjetas, ofrecer una experiencia de compra mejorada a tus clientes y procesar pagos para servicios, recargas de tiempo aire y datos de manera eficiente. Además, Yomp Mobile te ofrece la posibilidad de surtir tu negocio con Abarrotes Express*, lo que te permitirá contar con una amplia variedad de productos a precios competitivos. Con Yomp Mobile, llevarás tu negocio al siguiente nivel.",
      cssClass: "",
      dishabled: false
    },
    {
      question: "¿Qué servicios puedo ofrecer con Yomp Mobile?",
      idAnswer: "a-9",
      answers: "Los principales servicios que les podrás ofrecer a tus clientes son: - Recargas de Tiempo Aire: Telcel, Movistar, AT&T y ¡muchos más! - Pago de Servicios: Agua, Luz, Gas, TV de paga, pines electrónicos y ¡mucho más!   - Pago con tarjeta: al adquirir el dispositivo de cobro con tarjeta",
      cssClass: "",
      dishabled: false
    },
    {
      question: "¿Cómo puedo descargar la app Yomp Mobile?",
      idAnswer: "a-10",
      answers: "1. Descarga la app a través de Google Play. 2. Regístrate en nuestra app de Yomp! Mobile. 3. Recarga saldo a tu cuenta Yomp! Mobile. 4.Compra el dispositivo de cobro con tarjeta desde la app. 5. ¡Y listo! Tu dispositivo estará llegando a tu negocio sin costo.",
      cssClass: "",
      dishabled: false
    }
  ];

  questionPosition : any;
  displayAnswer : boolean = false;
  frequentQuestion : I_Frequent_question = {
    question : "",
    idAnswer : "",
    answers : "",
    cssClass : "",
    dishabled : false
  };
  collapseAnswerPosition : string = "";
  collapseSettings : string = "collapse collapse-horizontal ";

  /**
   * Load initial funtions.
   *
   * @memberof FrequentQuestionsComponent
   */
  ngOnInit() : void {
    this.setGridPositions();
  }

  /**
   * Set css class for display the questions.
   *
   * @memberof FrequentQuestionsComponent
   */
  setGridPositions() : void {
    for (let i = 1; i < this.frequentQuestions.length + 1; i++) {
      this.frequentQuestions[i - 1].cssClass = "question-" + i;
    }
  }

  /**
   * Set answer value of question selected.
   *
   * @param {number} question
   * @memberof FrequentQuestionsComponent
   */
  questionSelected(question : number) : void {
    this.validateDisplayAnswer(question);
    this.collapseSettings = this.collapseSettings.concat("show");
    this.frequentQuestions.forEach(question => {
      question.cssClass = question.cssClass.concat(" hoverOff");
      question.dishabled = true;
    });
    setTimeout(() => {
      this.frequentQuestion = this.frequentQuestions[question];
    }, 500);
    this.scrollTop();
  }

  setAnswerPosition(question : number) : void {
    this.collapseAnswerPosition = "collapseAnswerPosition-" + question;
    this.scrollTop();
  }

  closeAnswer() : void {
    this.frequentQuestions.forEach(question => {
      question.cssClass = question.cssClass.replace("hoverOff", "");
      question.dishabled = false;
    });
    this.collapseSettings = "collapse collapse-horizontal ";
    this.frequentQuestion = { question : "", idAnswer : "", answers : "", cssClass : this.frequentQuestion.cssClass, dishabled : this.frequentQuestion.dishabled };
  }

  scrollTop() : void {
    let question = document.getElementById(this.collapseAnswerPosition);
    if(question?.scrollTop != undefined) {
      question.scrollTop = 0;
    }
    
  }

  validateDisplayAnswer(question : number) : void {
    
    switch (true) {
      case this.questionPosition == undefined:
        this.questionPosition = question;
        this.displayAnswer =  true;
        break;
      case this.questionPosition != question:
        this.questionPosition = question;
        this.displayAnswer = false;
        break;
      default:
        this.questionPosition = question;
        this.displayAnswer =  true;
        break;
    }
    
  }

  setToggleSetting(isTarget : boolean, question : number) : string {
    let idName : string = "";

    if(isTarget) {
      idName = "#collapseAnswerPosition-" + question;
    }
    else {
      idName = "collapseAnswerPosition-" + question;
    }
    return idName;
  }

  navigationTo(id : string, code : string) : string {

    setTimeout(() => {

      let element = document.getElementById(id);

      let children = element?.getElementsByTagName('span');

      if(code != "" && children != undefined && children.length > 0) {

        this._renderer.listen(element, "click", (event) => {

          let tagName : string = event.target.tagName;

          if(children != undefined && tagName == "SPAN") {

            let idChild : string = event.target.id;

            let queryParam;
            let urlSplit : string[] = idChild.split("?");
            let url = urlSplit[0];

            if(urlSplit.length > 1) {
              let value = urlSplit[1];
              value = value.replace("{", `{"`).replace(":", `":"`).replace("}", `"}`);
              queryParam = JSON.parse(value);
            }
            
            this._navigation.goNavigation(url, queryParam);

          }
          
        });

      }
        
    }, 500);

    return code;

  }

}