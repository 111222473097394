<div class="container-component">
    <MenuComponent></MenuComponent>
    <div class="container-title">
        <p><span class="title-first-line">Déjanos tus</span><br>
        <span class="title-second-line">datos y te</span><br>
        <span class="title-third-line">contactaremos</span></p>
    </div>
    <form class="flex-form" [formGroup]="frmElement">
            <div class="item-form">
                <label class="label-size">Nombre(s)</label>
                <input type="text" class="form-control" formControlName="name">
            </div>
            <div class="item-form">
                <label class="label-size">Apellido Paterno</label>
                <input type="text" class="form-control" formControlName="lastName">
            </div>
            <div class="item-form">
                <label class="label-size">Apellido Materno</label>
                <input type="text" class="form-control" formControlName="mothersLastName">
            </div>
            <div class="item-form">
                <label class="label-size">Correo electrónico</label>
                <input type="text" class="form-control" formControlName="email" placeholder="email@ejemplo.com">
            </div>
            <div class="item-form">
                <label class="label-size">Celular (ingresa 10 dígitos)</label>
                <input type="text" class="form-control" formControlName="phone" maxLength="10">
            </div>
            <div class="item-form">
                <label class="label-size">Código postal</label>
                <input type="text" class="form-control" formControlName="zipCode" maxLength="5">
            </div>
            <div class="item-form">
                <label class="label-size">Interesado(a) en:</label>
                <select class="form-control" formControlName="platformId">
                    <option *ngFor="let platform of platformList" [value]="platform.id"> {{ platform.name }} </option>
                </select>
            </div>
            <div class="container-double-columns item-form">
                <label class="label-size">Comentarios</label>
                <input type="text" class="form-control" formControlName="comment">
            </div>
            <div class="container-double-columns container-terms-conditions">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="termsAccepted">
                </div>
                <label class="form-check-label">
                    He leído y acepto el <span class="url" (click)="acceptLegals()"> "Aviso de privacidad" </span>
                </label>
            </div>
        <div class="container-contact">
            <p>Contáctanos directamente al teléfono</p>
        </div>
        <div class="container-phone">
            <img src="../../../../assets/icons/png/ic_phone.png">
            <p>81 4160 2222</p>
        </div>
        <div class="submit-data url" (click)="submitData()">
            <p>Enviar información</p>
            <img src="../../../../assets/icons/png/ir.png">
        </div>
    </form>
</div>
<!-- Accept legal terms modal -->
<ModalComponent 
    [displayModal]="displayModalTermsNotAccepted" 
    [title]="'Nos faltan datos'" 
    [icon]="'../../../../assets/icons/png/ic_padlock.png'"
    [buttons]="termsNotAcceptedButtons"
    (displayModalEvent)="displayModayInvalidFormUpdatedForChild($event)"
    (buttonSelectedEvent)="readButtonSelected($event)">
    <div class="container-modal-content">
        <AcceptTermsComponent></AcceptTermsComponent>
    </div>
</ModalComponent>
<!-- Invalid form modal -->
<ModalComponent 
    [displayModal]="displayModalInvalidForm" 
    [title]="'Verificar información'" 
    [icon]="'../../../../assets/icons/png/ic_warning.png'"
    [buttons]="formModalButtons"
    (displayModalEvent)="displayModayInvalidFormUpdatedForChild($event)">
    <div class="container-modal-content">
        <InvalidFormComponent [invalidFields]="invalidFields"></InvalidFormComponent>
    </div>
</ModalComponent>
<!-- Display legal modal -->
<ModalComponent 
    [displayModal]="displayModalLegals" 
    [title]="legalDataTitle()"
    [buttons]="legalModalButtons"
    (displayModalEvent)="displayModayLegalsUpdatedForChild($event)" 
    (buttonSelectedEvent)="readButtonSelected($event)">
    <TabsComponent [data]="legalsData" [displayTitles]="true"></TabsComponent>
</ModalComponent>