import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { T_Http_Methods } from "src/app/interfaces/http-methods.type";
import { I_http_response, I_Result } from "src/app/interfaces/http-response.interface";
import { ModalStatus } from "src/app/interfaces/modal-status.interface";
import { ModalStatusService } from "../../modal-status.service";

@Injectable()
export class LaunchRequestsService {
    
    constructor(private _http: HttpClient, private _notificationService: ModalStatusService) { }

    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    /**
     * Set the token in header.
     *
     * @param {string} header
     * @param {string} value
     * @memberof LaunchRequestsService
     */
    updateHeaders(header: string, value: string): void {
        this.httpHeaders = this.httpHeaders.set(header, value);
    }
    
    /**
     * Remove the token in header.
     *
     * @param {string} name
     * @memberof LaunchRequestsService
     */
    removeHeader(name: string): void {
        this.httpHeaders = this.httpHeaders.delete(name);
    }
    
    /**
     * Convert param to query string.
     *
     * @private
     * @param {*} params
     * @return {*}  {string}
     * @memberof LaunchRequestsService
     */
    private toQueryString(params: any): string {

        let queryString = '';

        let first = true;

        const keys = Object.keys(params);

        for (const key of keys) {

            if (first) {
                queryString += '?';
                first = false;
            }

            else {
                queryString += '&';
            }

            queryString += `${key}=${params[key]}`;
        }
        
        return queryString;
    }
    
    /**
     * Send request to API.
     *
     * @param {T_Http_Methods} method
     * @param {string} url
     * @param {object} [filterOrBody]
     * @param {boolean} [showBaseMessage=true]
     * @param {*} [headers=this.httpHeaders]
     * @return {*}  {Observable<any>}
     * @memberof LaunchRequestsService
     */
    execute(method : T_Http_Methods, url : string, filterOrBody? : object,  showBaseMessage : boolean = true, headers = this.httpHeaders) : Observable<any> {

        let options : { body? : any, headers? : any } = { body : undefined, headers : headers };

        if(method == "GET") {

            url = url.concat(this.toQueryString(filterOrBody));

        }
        else {

            options.body = filterOrBody;

        }

        

        let error : I_Result = {
            status : 0,
            statusText : "",
            message : "",
            body : "",
            success : false
        }

        let result = this._http.request(
            method, 
            url, 
            options)
            .pipe(
                catchError(
                    this.handleError(
                        url, 
                        showBaseMessage,
                        error
                    )
                )
            );
        
        return result;
    }
    
    /**
     * Handler and format the error response from the service
     *
     * @private
     * @template T
     * @param {string} operation
     * @param {boolean} showBaseMessage
     * @param {I_Result} [result]
     * @return {*}  {(response: any) => Observable<T>}
     * @memberof LaunchRequestsService
     */
    private handleError(operation : string, showBaseMessage : boolean, result? : I_Result) : (response : any) => Observable<I_Result> {

        return (response: I_http_response) : Observable<any> => {

            if(result != null) {
                switch (true) {
                    case response.status != null:

                        if(showBaseMessage) {
                            let modalData : ModalStatus = {
                                iconUrl : "",
                                title : "Error",
                                message : "",
                                allowDismiss : false,
                                textButtons : ["Aceptar"]
                            }

                            modalData.message = this.getErrorMessage(response.status, response.message);

                            this._notificationService.show(modalData);
                        }
                        else {
                            result.status = response.status;
                            result.statusText = response.statusText;
                            result.message = response.message;
                            result.body = response.url;
                            result.success = response.ok;
                        }

                    break;
                    // case response.error === null:
                    //     return of(result);
                    // break;
                    // case response.error != null:
                    //     if(response.error.text == null) {

                    //     }
                    //     else if(response.statusText && response.statusText == 'Unknown Error'){
                    //         response.error = "Ocurrio un error al obtener la información, intenta nuevamente";
                    //     }
                    //     message = `API: ${response.error}`;
                    // break;
                    // case response.error.errors != null:
                    //     if(Array.isArray(response.error.errors)) {
                    //         const errors = response['error']['errors'];
                    //         if (errors.length) {
                    //             message = `API: ${response['error']['errors'][errors[0]]}`;
                    //         }
                    //     }
                    //     else {
                    //         if (response.error.hasOwnProperty('message')) {
                    //             message = `API: ${response.error.message}`;
                    //         }
                    //     }
                    // break;
                    default:
                    break;
                }
            }
                
            return of(result);
        }
            
    };

    /**
     * Gets the error message.
     *
     * @param {number} status
     * @param {string} messageResponse
     * @return {*}  {string}
     * @memberof LaunchRequestsService
     */
    getErrorMessage(status : number, messageResponse : string) : string {

        let message : string = "";

        switch (status) {
            case 0:
                message = "Red. No hay accesso a Internet.";
                break;
            case 404:
                message = "Servidor. Servicio no disponible.";
                break;
            default:
                break;
        }

        return message;

    }
}