import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { slide } from 'src/app/animations/transicion';
import { Navigation } from "../../../utils/navigation";

@Component({
	selector: 'YompPremiumComponent',
	templateUrl: './yomp-premium.component.html',
	styleUrls: ['./yomp-premium.component.css'],
  animations: [slide]
})
export class YompPremiumComponent {

	@HostBinding('@routeAnimation') routeAnimation = true;

	platform : object = { platform : "premium" };

	constructor(private _router : Router, private _activatedRoute : ActivatedRoute) { }

	protected _navigation = new Navigation(this._router, this._activatedRoute);

}