import { Component, Input } from '@angular/core';

@Component({
	selector: 'InvalidFormComponent',
	templateUrl: './invalid-form.component.html',
	styleUrls: ['./invalid-form.component.css']
})
export class InvalidFormComponent {

	@Input() invalidFields: string[] = [];
}