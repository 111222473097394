/**
 * Utils related at numerics processing.
 *
 * @export
 * @class Numeric
 */
export class Numeric {

    /**
     * Sum the numbers in array.
     *
     * @param {any[]} array
     * @param {string} propertyName
     * @return {*}  {number}
     * @memberof Numeric
     */
    sumAmountsInArray(array : any[], propertyName? : string) : number {

        let total : number = 0;

        if(array != null || array != undefined) {
            if(propertyName != null || propertyName != undefined) {
                total = array.reduce((acum, nexValue) => acum + Number.parseFloat(nexValue[propertyName.replace("$","")]), 0);
            }
            else {
                total = array.reduce((acum, nexValue) => acum + nexValue, 0);
            }
        }

        return total;

    }

    /**
     * Convert the currency value to number.
     *
     * @param {string} value
     * @return {*}  {string}
     * @memberof Numeric
     */
    convertCurrencyFormatToNumber(value : string) : number {
        let valueConverted : number = Number.parseFloat(value.replace("$", ""));
        return valueConverted;
    }

}