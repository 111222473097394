import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalStatusService } from 'src/app/services/modal-status.service';

@Component({
	selector: 'ModalComponent',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.css']
})
export class ModalComponent {

	@Input() displayModal : boolean = false;
	@Input() title: string = "";
	@Input() icon: string = "";
	@Input() buttons: string[] = [];
	@Output() displayModalEvent = new EventEmitter<boolean>();
	@Output() buttonSelectedEvent = new EventEmitter<string>();
	headerType : string = "";
	classModalBody : string = "";
	classModalFooter : string = "";

	constructor(private _modalStatus : ModalStatusService) { }

	/**
	 * Display or hidde the modal.
	 *
	 * @return {*}  {boolean}
	 * @memberof ModalComponent
	 */
	showModal() : boolean {
		
		if(this.icon != "" && this.icon != undefined) {
			this.headerType = "modal-header-container-with-icon";
			this.classModalBody = "modal-body-dark";
			this.classModalFooter = "modal-footer-dark";
		}
		else {
			this.headerType = "modal-header-container";
			this.classModalBody = "modal-body";
			this.classModalFooter = "modal-footer";
		}
		
		return this.displayModal;
	}

	/**
	 * Close the modal.
	 *
	 * @memberof ModalComponent
	 */
	closeModal() : void {
		this.displayModal = false;
		this.displayModalEvent.emit(this.displayModal);
	}

	/**
	 * Read the button selected.
	 *
	 * @param {string} button
	 * @memberof ModalComponent
	 */
	buttonSelected(button : string) : void {

		button = button.toLocaleLowerCase();

		this.buttonSelectedEvent.emit(button);

		this.closeModal();

	}
}