<div class="container-title">
    <p>Nosotros </p>
</div>
<div class="container-detail">
    <div class="container-about-us">
        <p class="container-subtitle"> ¿Quiénes <br>
            somos?
        </p>
        <p class="paragraph"> 
            Una empresa tecnológica y de distribución de abarrotes donde buscamos reescribir el futuro de la tiendita de la esquina
            por medio de la plataforma que conecta a los comerciantes del canal tradicional, con el mundo moderno de los negocios.
        </p>
        <p class="paragraph">
            Nuestro propósito es crear una red que empodera a dueños de “Tienditas de la esquina” y los acompaña en su operación,
            ayudándolos a:
        </p>
        <ol>
            <li>Comprar en Escala</li>
            <li>Mejorar su oferta</li>
            <li>Vender más</li>
            <li>Ser más eficientes para competir</li>
            <li>Convertirlos en agentes de cambio</li>
        </ol>
    </div>
    <div class="container-mission">
        <p class="container-subtitle"> Misión </p>
        <p class="paragraph">
            Generar el máximo valor para nuestros clientes, colaboradores, comunidades y accionistas, satisfaciendo en todo momento 
            y con excelencia las expectativas de nuestros consumidores.
        </p>
    </div>
    <div class="container-vision">
        <p class="container-subtitle"> Visión </p>
        <p class="paragraph">
            Ser líderes en todas las ocasiones de consumo de bebidas y alimentos en los mercados donde participamos, de forma rentable y sostenible.
        </p>
    </div>
</div>