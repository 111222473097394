<div class="container-title">
    <img src="../../../../assets/icons/svg/logo_premium.svg">
</div>
<div class="container-platform-detail">
    <p> 
        ¡Haz tu negocio más <span>competitivo!</span><br>
        <br>
        ¿Estás listo para <span>incrementar tus <br>
        ganancias?</span> 
    </p>
</div>
<div class="container-platform-image">
    <img src="../../../../assets/icons/png/yomp_premium.png">
</div>
<div class="container-commission-button">
    <button class="button-white-transparent" type="button" (click)="_navigation.goNavigation('commissions-simulator', platform)">
        <span class="span-btn-commission">Haz clic aquí y</span> <br>
        ¡calcula tus comisiones!
    </button>
</div>
<div class="container-text-arrow" (click)="_navigation.goNavigation('register-form', platform)">
    <p>¡Contrátala ya!</p>
    <img src="../../../../assets/icons/png/ir.png">
</div>