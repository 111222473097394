<div class="container-title">
    <p>Historias de éxito</p>
</div>
<div class="container-tutorials">
  <!-- <div class="container-subtitle tutorial">Tutoriales</div>
  <a class="btn btn-light play" target="_blank" [href]="urlTutorials">Reproducir</a> -->
  <iframe style="width: 55vw; height: 45vh;" [src]="this.links[5]" allowfullscreen></iframe>
</div>
<div class="container-subtitle">Testimonios</div>
<div class="container-videos">
  <div class="container-videos-scroll">
    <iframe *ngFor="let link of links" class="video" [src]="link" allowfullscreen></iframe>
  </div>
</div>