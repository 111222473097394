import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { BehaviorSubject, filter, Subscription } from "rxjs";

@Injectable()
export class UrlService {

    private urlSubject = new BehaviorSubject<string>("");

    public subscription$ = new Subscription();

    constructor(private _router : Router) {

        this._router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((response : any) => {
                this.urlSubject.next(response.url);
            }
        );

    }

    url$ = this.urlSubject.asObservable();

    /**
     * Reset default values of service.
     *
     * @memberof ScrollService
     */
    clearURL() : void {
        this.urlSubject.next("");
        this.subscription$.unsubscribe();
    }

}