import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { slide } from 'src/app/animations/transicion';
import { Navigation } from 'src/app/utils/navigation';

@Component({
  selector: 'BusinessGrowthComponent',
  templateUrl: './business-growth.component.html',
  styleUrls: ['./business-growth.component.css'],
  animations: [slide]
})
export class BusinessGrowthComponent {

  @HostBinding('@routeAnimation') routeAnimation = true;

  constructor(private _router : Router, private _activatedRoute : ActivatedRoute) { }

	protected _navigation = new Navigation(this._router, this._activatedRoute);

}