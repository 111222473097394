import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollService } from 'src/app/services/navigation/scroll.service';

interface IMenuItem {
    title : string,
    class : string,
    url : string,
    component : string
}

@Component({
  selector: 'MenuComponent',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {

    menuItems : IMenuItem[] = [];

    constructor(private _router : Router, private _scroll : ScrollService, private _routerAngular : Router) {}

    /**
     * Load initial methods.
     *
     * @memberof MenuComponent
     */
    ngOnInit() : void {
        this.setMenuItems();
    }
    
	/**
     * Navigate to component.
     *
     * @param {string} component
     * @memberof MenuComponent
     */
    goNavigation(component : string) : void {

        let isComponent : boolean = component.toLocaleLowerCase().includes("component");

        if(isComponent) {
            this._router.navigate(["list-view"]);
		    this._scroll.scrollTo(component);
        }
        else {
            this._routerAngular.navigate([component]);
        }
	}

    /**
     * Set the menu items.
     *
     * @memberof MenuComponent
     */
    setMenuItems() : void {
        this.menuItems.push(
            {
                title : 'Nosotros',
                class : 'nav-link',
                url : '',
                component : 'UsComponent'
            },{
                title : 'Servicios',
                class : 'nav-link',
                url : '',
                component : 'YompServicesComponent'
            },{
                title : 'Contacto',
                class : 'nav-link',
                url : '',
                component : 'register-form'
            },{
                title : 'Yomp! Premium',
                class : 'nav-link',
                url : '',
                component : 'YompPremiumComponent'
            },{
                title : 'Yomp! Mobile',
                class : 'nav-link',
                url : '',
                component : 'YompMobileComponent'
            },{
                title : 'Preguntas frecuentes',
                class : 'nav-link',
                url : '',
                component : 'FrequentQuestionsComponent'
            }
        )
    }
}