import { Component } from '@angular/core';
import { ScrollService } from 'src/app/services/navigation/scroll.service';
import { UrlService } from 'src/app/services/navigation/url.service';

@Component({
  selector: 'ListViewsComponent',
  templateUrl: './list-views.component.html',
  styleUrls: ['./list-views.component.css']
})
export class ListViewsComponent {

  constructor(private _scroll : ScrollService, private _url : UrlService) { }

  /**
   * Load initial methods.
   *
   * @memberof ListViewsComponent
   */
  ngOnInit() : void {
    this.scrollToComponent();
  }
  
  /**
   * Scroll by compoenent name.
   *
   * @memberof ListViewsComponent
   */
  scrollToComponent() : void {
    
    this._scroll.subscription$ = this._scroll.component$
      .subscribe((componentName : string) => {
        if(componentName != "") {
          let componentTop = document.querySelector<HTMLElement>(componentName);

          if(componentTop)
          {
            let componentPosition : number = componentTop.offsetTop;

            this._url.subscription$ = this._url.url$.subscribe(url => {

              if(componentTop && url == '/list-view')
                componentPosition = componentPosition - componentTop.offsetHeight;

            });

            this._url.clearURL();

            window.scrollTo(0, componentPosition);
          }
        }
      });
  }
  
	/**
   * Clear values.
   *
   * @memberof ListViewsComponent
   */
  ngOnDestroy() : void {
    this._scroll.clearScroll();
  }  
}