import { FormControl, FormGroup } from "@angular/forms";

const CURRENCY_FORMAT : string = "/^(?!0\.00)\d+(,\d{3})*(\.\d*)?$/gm";
const NO_ZERO_OR_LESS : string = "^[1-9][0-9]*$";
const ONLY_TRUE : string = "^(true)$";
const ONLY_TEXT : string = "^[a-z A-Z]*$";
const ONLY_NUMBERS : string = "^[0-9]*$";
const EMAIL_FORMAT : string = "[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}" + "\\@" + "[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}" + "(" + "\\." + "[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25}" + ")+";

export class RegularExpressions {

    /**
     * Error messages collection.
     *
     * @static
     * @param {string} validatorName
     * @return {*}  {object}
     * @memberof RegularExpressions
     */
    static getErrorMessage(validatorName : string) : string {

        let config = {
            "reOnlyCurrencyFormat" : "Formato de moneda incorrecto."
        }

        let message : string = config[validatorName as keyof Object].toString();

        return message;

    }

    /**
     * Get a regular expression.
     *
     * @param {string} name
     * @return {*}  {string}
     * @memberof RegularExpressions
     */
    getRegEx(name : string) : string {

        let regEx :  string = "";

        switch (name) {
            case "CURRENCY_FORMAT":
                regEx = CURRENCY_FORMAT;
                break;
            case "NO_ZERO_OR_LESS":
                regEx = NO_ZERO_OR_LESS;
                break;
            case "ONLY_TRUE":
                regEx = ONLY_TRUE;
                break;
            case "ONLY_TEXT":
                regEx = ONLY_TEXT;
                break;
            case "ONLY_NUMBERS":
                regEx = ONLY_NUMBERS;
                break;
            case "EMAIL_FORMAT":
                regEx = EMAIL_FORMAT;
                break;
            default:
                break;
        }

        return regEx;

    }

    /**
     * Get string value from parameter.
     *
     * @param {(FormControl | string)} value
     * @return {*}  {string}
     * @memberof RegularExpressions
     */
    getStringFromParameter(value : FormControl | any) : string {

        let valueToValidate : string = "";

        if(typeof(value) != "string") {

            const flag = value['value'];

            if(flag !== null && flag['length'] !== undefined && flag['length'] > 0) {
                valueToValidate = value.value.toString();
            }
        }
        else {
            valueToValidate = value;
        }

        return valueToValidate;

    }

    /**
     * Apply RegEx validation.
     *
     * @param {string} regEx
     * @param {(FormControl | string)} value
     * @return {*}  {(RegExpMatchArray | null)}
     * @memberof RegularExpressions
     */
    validateRegEx(regEx : string, value : FormControl | any) :  boolean {

        let valueToValidate = this.getStringFromParameter(value);

        let validation : RegExpMatchArray | null = valueToValidate.match(regEx);

        return validation != null;

    }

    /**
     * Validate if value have currency format. 
     *
     * @param {string} value
     * @return {*}  {boolean}
     * @memberof RegularExpressions
     */
    reOnlyCurrencyFormat(value : FormControl | string) : boolean {

        let isValid = this.validateRegEx(CURRENCY_FORMAT, value);

        return isValid;
    }

    /**
     * Validate if value is higher than 0.
     *
     * @param {(FormControl | string)} value
     * @return {*}  {boolean}
     * @memberof RegularExpressions
     */
    reZeroOrLessValue(value : FormControl | string) : boolean {
        let isValid = this.validateRegEx(NO_ZERO_OR_LESS, value);

        return isValid;
    }

    /**
     * Validate the invalid form controls.
     *
     * @param {FormGroup} form
     * @return {*} 
     * @memberof RegularExpressions
     */
    public getInvalidControls(value : FormGroup) : string[] {

        let invalidControls : string[] = [];

        const controls = value.controls;

        for (let name in controls) {
            if (controls[name].invalid) {
                invalidControls.push(name);
            }
        }

        return invalidControls;
    }

    /**
     * Validate text for not white spaces, undefined or null value.
     *
     * @param {string} text
     * @return {*}  {boolean}
     * @memberof RegularExpressions
     */
    notWhiteSpacesUndefinedOrNull(text : string) : boolean {

        let isValid : boolean = true;

        if(text == null || text == undefined) {
            isValid = false;
        }
        else {
            if(text.trim() == "") {
                isValid = false;
            }
            else {
                isValid = true;
            }
        }

        return isValid;
    }
}