import { Component, Input } from '@angular/core';
import { I_Tabs } from "../../../interfaces/legals.interface";

@Component({
	selector: 'TabsComponent',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.css']
})
export class TabsComponent {

	@Input() displayTitles : boolean = true;

	@Input() contentIsHtml : boolean = false;
	
    @Input() data: I_Tabs[] = [
		{ 
			title : "No data", 
			content : "Empty", 
			cssClassTab : "nav-link active", 
			idTab : "noData", 
			dataBsTarget : "#no-data-pane", 
			ariaControls : "no-data-pane", 
			ariaSelected : true, 
			cssClassContent : "tab-pane fade show active",
			idContent : "no-data-pane",
			ariaLabelledby : "noData"
		}
	];

}