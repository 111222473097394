<div class="tab-container">
    <ul *ngIf="displayTitles" class="nav nav-tabs" id="myTab" role="tablist">
        <li *ngFor="let item of data" class="nav-item" role="presentation">
            <button [class]="item.cssClassTab" [id]="item.idTab" data-bs-toggle="tab" [attr.data-bs-target]="item.dataBsTarget" type="button" role="tab" [attr.aria-controls]="item.ariaControls" [attr.aria-selected]="item.ariaSelected">{{ item.title }}</button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div *ngIf="contentIsHtml; else isHtmlContent">
            <div *ngFor="let item of data" [class]="item.cssClassContent" [id]="item.idContent" role="tabpanel" [attr.aria-labelledby]="item.ariaLabelledby" tabindex="0">{{ item.content }}</div>
        </div>
        <ng-template #isHtmlContent>
            <div *ngFor="let item of data" [class]="item.cssClassContent" [id]="item.idContent" role="tabpanel" [attr.aria-labelledby]="item.ariaLabelledby" tabindex="0" [innerHtml]="item.content | HtmlSanitizer"></div>
        </ng-template>
    </div>
</div>