<div class="container-title">
    <img src="../../../../assets/icons/svg/logo_mobile.svg">
</div>
<div class="container-platform-detail">
    <p> 
        <span>¡Incrementa tus ganancias</span> <br>
        al aceptar <span>pagos con tarjeta</span> <br>
        de manera segura!
    </p>
</div>
<div class="container-platform-image">
    <img src="../../../../assets/icons/png/yomp_mobile.png">
</div>
<div class="container-commission-button">
    <button class="button-white-transparent" type="button" (click)="_navigation.goNavigation('commissions-simulator', platform)">
        <span class="span-btn-commission">Haz clic aquí y</span> <br>
        ¡calcula tus comisiones!
    </button>
</div>
<div class="container-download-now">
    <span>¡Descárgala Ya!</span>
    <img src="../../../../assets/icons/png/ic_google_play.png" (click)="getYompMobilePlayStoreUrl()">
</div>
<div class="container-text-arrow" (click)="_navigation.goNavigation('yomp-mobile-steps')">
    <p>Ver más</p>
    <img src="../../../../assets/icons/png/ir.png">
</div>