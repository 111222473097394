import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I_Commission_Simulator } from 'src/app/interfaces/commission-simulator.interface';
import { I_BASE_DAILY_GAIN, Settings } from 'src/app/interfaces/globals';
import { Navigation } from 'src/app/utils/navigation';
import { Numeric } from "../../../utils/numeric";
import { RegularExpressions } from "../../../utils/regular-expressions";

declare var SETTINGS : Settings;
@Component({
	selector: 'CommissionsSimulatorComponent',
	templateUrl: './commissions-simulator.component.html',
	styleUrls: ['./commissions-simulator.component.css']
})
export class CommissionsSimulatorComponent {

	protected utils = new Numeric();
	protected regEx = new RegularExpressions();
	commissionSimulatorBaseData : I_Commission_Simulator[] = [];
	invalidFields : I_Commission_Simulator[] = [];
	platform : string = "";
	instructions : string = "";
	regExOnlyNumbers : string = this.regEx.getRegEx("ONLY_NUMBERS");

	totalDailyGain : number = 0.00;
	totalMonthlyGain : number = 0.00;
	commissionAmount : number[] = SETTINGS.COMMISSIONS.AMOUNTS;
	
	constructor(private _router : Router, private _activatedRoute : ActivatedRoute, private _changeDetectorRef : ChangeDetectorRef) { }

	protected _navigation = new Navigation(this._router, this._activatedRoute);

	/**
	 * Load initial methods.
	 *
	 * @memberof CommissionsSimulatorComponent
	 */
	ngOnInit() : void {
		this.platform = this._navigation.getQueryParamValue("platform");
		this.getInstructions();
		this.getCommissionData();
	  }

	/**
	 * Get the instructions.
	 *
	 * @memberof CommissionsSimulatorComponent
	 */
	getInstructions() : void {
		this.instructions = "El valor máximo a ingresar en los campos de Número de transacciones es 100.";
	}

	/**
	 * Get the commission data.
	 *
	 * @memberof CommissionsSimulatorComponent
	 */
	getCommissionData() : void {

		let commissions : I_BASE_DAILY_GAIN = {
			CELLPHONE_MINUTES : "",
			DATA : "",
			SERVICES : ""
		};

		if(this.platform == "premium") {
			commissions = SETTINGS.COMMISSIONS.BASE_DAILY_GAIN_PREMIUM;
		}
		else if(this.platform == "mobile") {
			commissions = SETTINGS.COMMISSIONS.BASE_DAILY_GAIN_PLUS;
		}

		commissions.CELLPHONE_MINUTES

		this.commissionSimulatorBaseData.push(
			{
				commissionConfigurationId: 0,
				serviceType: "Tiempo aire",
				nameTxCount: "",
				valueTxCount: 0,
				nameTxAmount: "",
				valueTxAmount: 0,
				baseDailyGain: commissions.CELLPHONE_MINUTES,
				dailyGain: 0.00,
				monthlyGain: 0.00,
				tableClass: "borderTopLine",
				classInvalidCountData: "",
				classInvalidAmountData: ""
			},
			{
				commissionConfigurationId: 0,
				serviceType: "Datos",
				nameTxCount: "",
				valueTxCount: 0,
				nameTxAmount: "",
				valueTxAmount: 0,
				baseDailyGain: commissions.DATA,
				dailyGain: 0.00,
				monthlyGain: 0,
				tableClass: "",
				classInvalidCountData: "",
				classInvalidAmountData: ""
			},
			{
				commissionConfigurationId: 0,
				serviceType: "Servicios",
				nameTxCount: "",
				valueTxCount: 0,
				nameTxAmount: "",
				valueTxAmount: 0,
				baseDailyGain: commissions.SERVICES,
				dailyGain: 0.00,
				monthlyGain: 0.00,
				tableClass: "",
				classInvalidCountData: "",
				classInvalidAmountData: ""
			}
		);

		for (let i = 0; i < this.commissionSimulatorBaseData.length; i++) {
			this.commissionSimulatorBaseData[i].commissionConfigurationId = i;
			// this.commissionSimulatorBaseData[i].nameTxCount = `txCount${i}`;
			this.commissionSimulatorBaseData[i].nameTxAmount = `txAmount${i}`;
		}
		
	}
	
	/**
	 * Calculate the commissions amount.
	 *
	 * @memberof CommissionsSimulatorComponent
	 */
	calculateCommissions() : void {
		
		for (let i = 0; i < this.commissionSimulatorBaseData.length; i++) {

			let amount : number = this.commissionSimulatorBaseData[i].valueTxAmount;

			if(this.commissionSimulatorBaseData[i].serviceType == "Servicios") {
				amount = 1;
			}

			let commissionAssigned : number = 0;

			switch (true) {
				case this.commissionSimulatorBaseData[i].baseDailyGain.includes("$"):
					commissionAssigned = Number.parseFloat(this.commissionSimulatorBaseData[i].baseDailyGain.replace("$", ""));
					break;
				case this.commissionSimulatorBaseData[i].baseDailyGain.includes("%"):
					commissionAssigned = Number.parseFloat(this.commissionSimulatorBaseData[i].baseDailyGain.replace("%", "")) / 100;
					break;
				default:
					break;
			}

			if(isNaN(amount)) {
				amount = 0.00;
			}

			this.commissionSimulatorBaseData[i].valueTxAmount = Number.parseFloat(amount.toFixed(2));

			this.commissionSimulatorBaseData[i].dailyGain = 
				(amount * this.commissionSimulatorBaseData[i].valueTxCount) * commissionAssigned;

			this.commissionSimulatorBaseData[i].monthlyGain = this.commissionSimulatorBaseData[i].dailyGain * 30
		}
		
		this.totalDailyGain = this.utils.sumAmountsInArray(this.commissionSimulatorBaseData, "dailyGain");

		this.totalMonthlyGain = this.utils.sumAmountsInArray(this.commissionSimulatorBaseData, "monthlyGain");

		this.setInvalidFieldColor();

	}

	/**
	 * Get the amount selected.
	 *
	 * @param {*} amount
	 * @param {number} index
	 * @memberof CommissionsSimulatorComponent
	 */
	amountSelected(amount : any, index : number) : void {

		this.commissionSimulatorBaseData[index].classInvalidCountData = "";
		this.commissionSimulatorBaseData[index].classInvalidAmountData = "";
		this.commissionSimulatorBaseData[index].valueTxAmount = Number.parseInt(amount.value);

	}

	/**
	 * Detect changes in array items.
	 *
	 * @param {number} index
	 * @param {I_Commission_Simulator} item
	 * @return {*}  {number}
	 * @memberof CommissionsSimulatorComponent
	 */
	trackByFn(index : number, item : I_Commission_Simulator) : number {
		
		if(item.valueTxCount == null) {
			item.valueTxCount = 0;
		}

		let countNumericStr : string = item.valueTxCount.toString().slice(0,3)

		let countNumeric : number = Number.parseInt(countNumericStr);
		
		if(typeof countNumeric != 'number' || Number.isNaN(countNumeric)) {
			countNumeric = 0;
		}
		else if(countNumeric > 100) {
			countNumeric = 100;
		}
		else if(countNumeric <= 100) {
			// countNumeric = 100;
		}
		else {
			countNumeric = 0;
		}

		item.valueTxCount = Number.parseInt(countNumeric.toString().slice(0,3));

		return item.commissionConfigurationId;
		
	}

	/**
	 * Enabled or disabled submit button.
	 *
	 * @return {*}  {boolean}
	 * @memberof CommissionsSimulatorComponent
	 */
	disabledButton() : boolean {

		this.invalidFields = 
			this.commissionSimulatorBaseData.filter(commission => 
				typeof commission.valueTxCount != 'number' ||
				commission.serviceType != "Servicios" &&
				(
					//Have Tx count and have't Tx amount.
					(commission.valueTxCount > 0 && commission.valueTxAmount == 0) ||
					//Have't Tx count and have Tx amount.
					(commission.valueTxCount <= 0 && commission.valueTxAmount > 0) ||
					//Have't Tx count and have't Tx amount.
					(commission.valueTxCount <= 0 && commission.valueTxAmount == 0)
				)
			);

		let disabled : boolean = this.invalidFields.length == this.commissionSimulatorBaseData.length - 1;

		return disabled;

	}

	/**
	 * Set styles of invalid data.
	 *
	 * @param {I_Commission_Simulator[]} invalidFields
	 * @memberof CommissionsSimulatorComponent
	 */
	setInvalidFieldColor() : void {

		this.commissionSimulatorBaseData = this.commissionSimulatorBaseData.map(data => {

			this.invalidFields.forEach(invalid => {

				if(data.serviceType == invalid.serviceType) {

					if(invalid.serviceType != "Services") {
						if(data.valueTxCount <= 0 && data.valueTxAmount > 0) {
							data.classInvalidCountData = "invalidData";
						}
						else if(data.valueTxCount > 0 && data.valueTxAmount <= 0) {
							data.classInvalidAmountData = "invalidData";
						}
						else {
							data.classInvalidCountData = "";
							data.classInvalidAmountData = "";
						}
					}
					
				}

			});

			return data;
		});	

	}

	/**
	 * Validations of tx count.
	 *
	 * @param {*} event
	 * @param {number} index
	 * @return {*}  {number}
	 * @memberof CommissionsSimulatorComponent
	 */
	validateMaxLength(event : any, index : number) : number {

		let valueStr : string = event.value.toString().slice(0,3);

		let value = Number.parseInt(valueStr);

		if(valueStr.length <= 2 && (typeof value == 'number' || !Number.isNaN(value))) {
			value = Number.parseInt(valueStr);
		}

		if(this.commissionSimulatorBaseData[index].valueTxCount != value || event.value.length > 2) {
			this.commissionSimulatorBaseData[index].valueTxCount = value;
			this._changeDetectorRef.markForCheck();
			this._changeDetectorRef.detectChanges();
		}

		return value;

	}

}