<div class="container-component">
    <MenuComponent></MenuComponent>
    <div class="container-title">
        <p>Simulador de <br> </p>
        <p class="titleSecondLine">comisiones</p>
    </div>
    <div class="container-subtitle">{{ instructions }}</div>
    <div class="container-detail">
        <table class="table">
            <thead>
                <tr>
                    <th class="table-title">Tipo de <br> servicio</th>
                    <th class="table-title">Número de <br> transacciones</th>
                    <th class="table-title">Monto a <br> transaccionar</th>
                    <th class="table-title">Comisiones <br> asignadas</th>
                    <th class="header-divider">
                        <tr>
                            <th class="table-title" colspan="2">Ganancias de comisiones</th>
                        </tr>
                        <tr>
                            <th class="table-title">Ganancia diaria</th>
                            <th class="table-title">Ganancia mensual</th>
                        </tr>
                    </th>
                </tr>
            </thead>
            <tbody class="border-top-bottom">
                <tr *ngFor="let commission of commissionSimulatorBaseData; let i = index; trackBy : trackByFn">
                    <td>
                        <button class="btn service-type">{{ commission.serviceType }}</button>
                    </td>
                    <td>
                        <input [class]="commission.classInvalidCountData + ' center'" name="commission.nameTxCount{{i}}" (keydown)="commission.valueTxCount = validateMaxLength($event.target, i)" oninput="validity.valid || (value=0)" [(ngModel)]="commission.valueTxCount" type="number" [min]="0" [max]="100" step="1">
                    </td>
                    <td>
                        <div *ngIf="commission.serviceType != 'Servicios'">
                            <select (change)="amountSelected($event.target, i)" [class]="commission.classInvalidAmountData">
                                <option *ngFor="let amount of commissionAmount" [value]="amount"> {{ '\$' + amount }} </option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <input class="center" [name]="commission.baseDailyGain" type="text" [(ngModel)]="commission.baseDailyGain" readonly>
                    </td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td class="revenue">
                                        {{ commission.dailyGain | currency }}
                                    </td>
                                    <td class="revenue">
                                        {{ commission.monthlyGain | currency }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td></td>
                    <td>
                        <button class="btn calculate" (click)="calculateCommissions()" [disabled]="disabledButton()">Calcular</button>
                    </td>
                    <td></td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td class="revenue">
                                        {{ totalDailyGain | currency }}
                                    </td>
                                    <td class="revenue">
                                        {{ totalMonthlyGain | currency }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>