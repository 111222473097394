<div class="container-component">
    <MenuComponent></MenuComponent>
    <div class="container-title">
        <p>Con yomp! <br> lo tienes todo</p>
    </div>
    <div class="container-detail">
        <div class="list-items">
            <div class="item-service" *ngFor="let service of services">
                <img [src]="service.icon">
                <p> {{ service.title }} </p>
            </div>
        </div>
        <div class="go" (click)="registerFormNavigation()">
            <p class="url">La quiero</p>
            <img src="../../../../assets/icons/png/ir.png">
        </div>
    </div>
</div>