import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ModalStatus } from "../interfaces/modal-status.interface";

/**
 * Modal service.
 */
@Injectable()
export class ModalStatusService {

    private emptyModalStatus : ModalStatus = {
        iconUrl : "",
        title : "",
        message : "",
        allowDismiss : false,
        textButtons : []
    }
    
    private messageInfo = new BehaviorSubject<ModalStatus>(this.emptyModalStatus);
    
    public messageInfo$ = this.messageInfo.asObservable();
    
    private confirmationResponseBS = new BehaviorSubject<string>("");
    
    public confirmationResponse$ = this.confirmationResponseBS.asObservable();

    public subscription$ = new Subscription();
    
    /**
     * Display modal and set data.
     *
     * @param {ModalStatus} data
     * @memberof ModalStatusService
     */
    show(data : ModalStatus) {
        
        this.messageInfo.next(data);

    }
    
    /**
     * Set the confirmation modal
     *
     * @param {boolean} response
     * @memberof ModalStatusService
     */
    setConfirmation(response : string) : void {

        this.confirmationResponseBS.next(response);

        this.clearModal();

    }

    /**
     * Reset default values of service.
     *
     * @memberof ModalStatusService
     */
    clearModal() : void {

        this.confirmationResponseBS.next("");

        this.messageInfo.next(this.emptyModalStatus);

        this.subscription$.unsubscribe();

    }

}