import { Component, HostBinding } from '@angular/core';
import { slide } from 'src/app/animations/transicion';
import { I_Video } from 'src/app/interfaces/video-interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const URL_TURORIALS : string = 'https://www.youtube.com/playlist?list=PLwtoW3zROZZ-IrfzyWj8vGjFGs3X5k-Gy';
@Component({
  selector: 'SuccessStoriesComponent',
  templateUrl: './success-stories.component.html',
  styleUrls: ['./success-stories.component.css'],
  animations: [slide]
})
export class SuccessStoriesComponent {

  @HostBinding('@routeAnimation') routeAnimation = true;

  constructor(private _sanitizer : DomSanitizer) {
    this.videos.forEach(video => {
      this.links.push(_sanitizer.bypassSecurityTrustResourceUrl(video.iframeUrl))
    });
  }

  videos : I_Video[] = [
    { 
      url:"https://www.youtube.com/watch?v=TdqdnKlojJc", 
      iframeUrl: "https://www.youtube.com/embed/TdqdnKlojJc",
      // code: '<iframe width="560" height="315" src="https://www.youtube.com/embed/TdqdnKlojJc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
    },
    { 
      url:"https://www.youtube.com/watch?v=T7_IdQcCtz4&t=19s",
      iframeUrl: "https://www.youtube.com/embed/T7_IdQcCtz4",
      // code: '<iframe width="560" height="315" src="https://www.youtube.com/embed/T7_IdQcCtz4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
    },
    { 
      url:"https://www.youtube.com/watch?v=dnCmBHHn2PQ", 
      iframeUrl: "https://www.youtube.com/embed/dnCmBHHn2PQ",
      // code: '<iframe width="560" height="315" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
    },
    { 
      url:"https://www.youtube.com/watch?v=wmfJkBQA2Dg", 
      iframeUrl: "https://www.youtube.com/embed/wmfJkBQA2Dg",
      // code: '<iframe width="560" height="315" src="https://www.youtube.com/embed/wmfJkBQA2Dg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
    },
    { 
      url:"https://www.youtube.com/watch?v=qAq5_PNYUM8", 
      iframeUrl: "https://www.youtube.com/embed/qAq5_PNYUM8",
      // code: '<iframe width="560" height="315" src="https://www.youtube.com/embed/qAq5_PNYUM8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
    },
    { 
      url:"https://www.youtube.com/watch?v=d_9s2Xu4BfI&t=4s", 
      iframeUrl: "https://www.youtube.com/embed/d_9s2Xu4BfI",
      // code: '<iframe width="560" height="315" src="https://www.youtube.com/embed/d_9s2Xu4BfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
    }
  ];

  links : SafeResourceUrl[] = [];

  urlTutorials : string = "https://www.youtube.com/playlist?list=PLwtoW3zROZZ-IrfzyWj8vGjFGs3X5k-Gy";

  goPlay() : void {
    document.location.href = URL_TURORIALS;
  }

}