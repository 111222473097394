import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from '@angular/common/http';

// Components
import { AcceptTermsComponent }               from './components/navigation/register-form/modal/accept-terms/accept-terms.component';
import { AppComponent }                       from './app.component';
import { BusinessGrowthComponent }            from './components/transicion-views/business-growth/business-growth.component';
import { CommissionsSimulatorComponent }      from './components/navigation/commissions-simulator/commissions-simulator.component';
import { FrequentQuestionsComponent }         from './components/transicion-views/frequent-questions/frequent-questions.component';
import { HomeComponent }                      from './components/home/home.component';
import { InvalidFormComponent }               from './components/navigation/register-form/modal/invalid-form/invalid-form.component';
import { TabsComponent }                      from './utils/components/tabs/tabs.component';
import { LINKS }                              from "./app.routing";
import { ListViewsComponent }                 from './components/list-views/list-views.component';
import { MenuComponent }                      from './components/menu/menu.component';
import { ModalComponent }                     from './utils/components/modal/modal.component';
import { NavigationComponent }                from './utils/components/navigation/navigation.component';
import { RegisterFormComponent }              from './components/navigation/register-form/register-form.component';
import { RegularExpressionMessageComponent }  from './utils/components/regular-expression-message/regular-expression-message.component';
import { SuccessStoriesComponent }            from './components/transicion-views/success-stories/success-stories.component';
import { UsComponent }                        from './components/transicion-views/us/us.component';
import { YompMobileComponent }                from './components/transicion-views/yomp-mobile/yomp-mobile.component';
import { YompMobileStepsComponent }           from './components/navigation/yomp-mobile-steps/yomp-mobile-steps.component';
import { YompPremiumComponent }               from './components/transicion-views/yomp-premium/yomp-premium.component';
import { YompServicesComponent }              from './components/transicion-views/yomp-services/yomp-services.component';
// Services
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { LaunchRequestsService }              from './services/requests/launch-requests/launch-requests.service';
import { ModalStatusService }                 from './services/modal-status.service';
import { RegularExpressions }                 from './utils/regular-expressions';
import { RequestsBaseService }                from './services/requests/request-base/requests-base.service';
import { ScrollService }                      from './services/navigation/scroll.service';
import { UrlService }                         from './services/navigation/url.service';
// Pipes
import { HtmlSanitizerPipe }                  from './pipes/html-sanitizer.pipe';

@NgModule({
  declarations: [
    //Components
    AcceptTermsComponent,
    AppComponent,
    BusinessGrowthComponent,
    CommissionsSimulatorComponent,
    FrequentQuestionsComponent,
    HomeComponent,
    InvalidFormComponent,
    TabsComponent,
    ListViewsComponent,
    MenuComponent,
    ModalComponent,
    NavigationComponent,
    RegisterFormComponent,
    RegularExpressionMessageComponent,
    SuccessStoriesComponent,
    UsComponent,
    YompMobileComponent,
    YompMobileStepsComponent,
    YompPremiumComponent,
    YompServicesComponent,
    //Pipes
    HtmlSanitizerPipe
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LINKS,
    BrowserModule,
    BrowserAnimationsModule
  ],
  providers: [
    LaunchRequestsService,
    ModalStatusService,
    RegularExpressions,
    RequestsBaseService,
    ScrollService,
    UrlService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }