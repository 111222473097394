<nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li routerLink="/">
            <img src="../../../assets/icons/png/yomp_logo_name.png" class="alignMenu" width="10%" height="10%">
          </li>
          <li class="nav-item menu-button" *ngFor="let item of menuItems">
            <a *ngIf="item.url != ''" [class]="item.class" aria-current="page" [href]="item.url" target="_blank">{{ item.title }}</a>
            <a *ngIf="item.url == ''" [class]="item.class" aria-current="page" (click)="goNavigation(item.component)">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </div>
</nav>