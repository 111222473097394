import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollService } from 'src/app/services/navigation/scroll.service';

@Component({
	selector: 'YompMobileStepsComponent',
	templateUrl: './yomp-mobile-steps.component.html',
	styleUrls: ['./yomp-mobile-steps.component.css']
})
export class YompMobileStepsComponent {

	constructor(private _router : Router, private _scroll : ScrollService) {}

	/**
	 * Navigate to YompMobileComponent.
	 *
	 * @memberof YompMobileStepsComponent
	 */
	goNavigation() : void {
		this._router.navigate(["list-view"]);
		this._scroll.scrollTo("YompMobileComponent");
	}

}