import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { slide } from 'src/app/animations/transicion';
import { Settings } from 'src/app/interfaces/globals';
import { Navigation } from 'src/app/utils/navigation';

declare var SETTINGS : Settings;

@Component({
	selector: 'YompServicesComponent',
	templateUrl: './yomp-services.component.html',
	styleUrls: ['./yomp-services.component.css'],
  	animations: [slide]
})
export class YompServicesComponent {

	constructor(private _router : Router, private _activatedRoute : ActivatedRoute) { }

	protected _navigation = new Navigation(this._router, this._activatedRoute);

	@HostBinding('@routeAnimation') routeAnimation = true;

	platform : object = { platform : "premium" };

	/**
	 * Get the Yomp Mobile play store url.
	 *
	 * @memberof YompServicesComponent
	 */
	getYompMobilePlayStoreUrl() : void {

		window.open(SETTINGS.PLAY_STORE.YOMP_MOBILE, '_blank');

	}

}