<div *ngIf="showModal()">
    <div class="modal fade" id="exampleModalCenter">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div [class]="headerType">
                        <img *ngIf="icon" [src]="icon">
                        <button class="btn-close btn-close-white" type="button" (click)="buttonSelected('cerrar')"></button>
                        <h6>{{ title }}</h6>
                    </div>
                </div>
                <div [class]="classModalBody">
                    <div class="modal-body-container">
                        <ng-content></ng-content>
                    </div>
                </div>
                <div [class]="classModalFooter">
                    <div class="container-commission-button" *ngFor="let button of buttons">
                        <button class="button-white-transparent" type="button" (click)="buttonSelected(button)">
                            {{ button }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>