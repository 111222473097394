import { ChangeDetectorRef, Component } from '@angular/core';
import { ModalStatus } from './interfaces/modal-status.interface';
import { ModalStatusService } from './services/modal-status.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private _notificationService: ModalStatusService, private _changeDetectorRef : ChangeDetectorRef) { }

  modal : ModalStatus = {
    iconUrl : "",
    title : "",
    message : "",
    allowDismiss : false,
    textButtons : []
  }

  showModal : boolean = false;

  /**
   * Load initial methods.
   *
   * @memberof AppComponent
   */
  ngOnInit() : void {

    this.displayModal();

  }

  /**
   *
   *
   * @memberof AppComponent
   */
  displayModal() : void {

    this._notificationService.messageInfo$.subscribe((response : ModalStatus) => {

      if(response.title != "") {

        this.modal = response;

        this.showModal = true;

        this._changeDetectorRef.detectChanges();

      }

    });
    
  }

  /**
   * Close the modal.
   *
   * @param {boolean} event
   * @memberof AppComponent
   */
  displayBaseModal(event : boolean) : void {

    this.showModal = event

  }

  /**
   * Read button selected.
   *
   * @param {string} event
   * @memberof AppComponent
   */
  readButtonSelected(event : string) : void {

    this._notificationService.setConfirmation(event);

  }

}