<div class="container-component">
    <MenuComponent></MenuComponent>
    <div class="container-title">
        <p>Obtén tu dispositivo de <br>
        cobro en ¡cinco pasos!</p>
    </div>
    <div class="container-steps">
        <img src="../../../../assets/icons/png/yomp_mobile_steps.png">
    </div>
    <div class="container-text-arrow" (click)="goNavigation()">
        <p>Regresar</p>
        <img src="../../../../assets/icons/png/ir.png">
    </div>
</div>