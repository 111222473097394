import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { T_Apis_Collection } from "src/app/interfaces/apis-collection.type";
import { Settings } from "src/app/interfaces/globals";
import { T_Http_Methods } from "src/app/interfaces/http-methods.type";
import { LaunchRequestsService } from "../launch-requests/launch-requests.service";

declare var SETTINGS : Settings;

@Injectable()
export class RequestsBaseService {

    constructor(
        private _launch: LaunchRequestsService
    ) { }

    public sending : boolean = false;

    /**
     * Concatenate the url.
     *
     * @param {string} urlApi
     * @param {string} urlController
     * @param {string} urlEndpoint
     * @return {*}  {string}
     * @memberof RequestsBaseService
     */
    buildURL(urlApi : T_Apis_Collection, urlController : string, urlEndpoint : string = "") : string {

        let apiPropertyName : string = Object.keys(SETTINGS.API_SETTINGS).find(property => property.includes(urlApi)) ?? "";

        let apiObject : Object = SETTINGS.API_SETTINGS[apiPropertyName as keyof Object];

        let apiName : any = apiObject["URL" as keyof Object]
        
        let url : string =  SETTINGS.API_SETTINGS.BASE_URL + 
                            apiName + 
                            urlController + 
                            urlEndpoint;

        return url;
    }
    
    /**
     * Performs a new GET, POST, PUT, PATCH and DELETE request for the API and the endpoint passed as parameter.
     *
     * @param {T_Http_Methods} requestType
     * @param {string} requestUrl
     * @param {object} [filterOrBody]
     * @param {boolean} [showBaseMessage]
     * @return {*}  {Observable<any>}
     * @memberof RequestsBaseService
     */
    sendRequest(requestType : T_Http_Methods, requestUrl : string, filterOrBody? : object, showBaseMessage? : boolean) : Observable<any> {
        
        return this._launch.execute(requestType, requestUrl, filterOrBody, showBaseMessage);

    }

}